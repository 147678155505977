<template>
  <div>
    <b-container class="bv-example-row ">
      <b-row class="justify-content-sm-center">
        <b-col cols="12" sm="4"
          ><div class="my-2"><logo /></div
        ></b-col>
      </b-row>
    </b-container>
    <b-container class="bv-example-row py-2 px-4">
      <b-row class="justify-content text-center p2">
        <b-col cols="12" sm="3">
          กรุณาเลือกค่ายเกมส์
        </b-col>
        <b-col cols="12" sm="4">
          <b-form-select
            v-model="selected"
            :options="providers"
            @change="onProviderChange()"
          ></b-form-select>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="bv-example-row px-4">
      <b-row class="justify-content">
        <b-col v-for="(list, index) in lists" :key="index" cols="6" sm="3">
          <div
            class="p1 d-flex flex-column justify-content-center align-items-center game-block"
          >
            <b-img :src="list.image.vertical" style="width:70%" />
            <span class="py-1">{{ list.gameName }}</span>
            <b-button
              class="play-button align-self-center"
              style="position:absolute; top:30%;"
              variant="primary"
              @click="onGameSelected(list)"
            >
              เข้าเล่น
            </b-button>
          </div>
        </b-col>
      </b-row></b-container
    >
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BImg,
  BButton,
  BFormSelect
} from 'bootstrap-vue'

import Logo from './component/Logo.vue'

import { getUserData } from '../auth/utils'

export default {
  components: {
    Logo,
    BRow,
    BCol,
    BContainer,
    BImg,
    BButton,
    BFormSelect
  },
  data() {
    return {
      lists: [],
      user: null,
      providers: [],
      selected: '',
      options: [
        { value: 'pg', text: 'PG Soft' },
        { value: 'slotxo', text: 'SlotXo | Joker' },
        { value: 'spg', text: 'Spade Gaming' },
        { value: 'pragmaticslot', text: 'Pragmatic Play' },
        { value: 'microgame', text: 'Micro Gaming' },
        { value: 'ambslot', text: 'AMB Slot' },
        { value: 'jili', text: 'Jili' },
        { value: 'relaxgame', text: 'Relax Gaming' },
        { value: 'allwayspin', text: 'AllWaySpin' },
        { value: 'booongo', text: 'Booongo' },
        { value: 'simpleplay', text: 'SimplePlay' },
        { value: 'yggdrasil', text: 'Yggdrasil' },
        { value: 'wazdandirect', text: 'Wazdan Direct' },
        { value: 'live22', text: 'Live22' },
        { value: 'askmebetslot', text: 'Askmebet' },
        { value: 'ameba', text: 'Ameba' },
        { value: 'ganapati', text: 'Gamatron' },
        { value: 'kagaming', text: 'KA Gaming' },
        { value: 'funtagaming', text: 'Funta Gaming' },
        { value: 'evoplay', text: 'Evo Play' }
      ]
    }
  },
  async beforeMount() {
    this.user = getUserData()
    await this.getSlotsGames()
    await this.getProviders()
  },

  methods: {
    onProviderChange() {
      console.log(this.selected)
      window.location.href = `slots?providor=${this.selected}`
    },
    onGameSelected(game) {
      console.log(game)
      window.open(`login/slot?p=${this.selected}&g=${game.id}`, '_blank')
    },
    async getSlotsGames() {
      const casino = this.$route.query.providor
      console.log('a', casino)
      this.selected = casino
      const lists = await this.$http.post(`member/game/lists`, {
        provider: casino,
        tab: 'Slot'
      })

      if (this.user.agent_username === '5kdb12441') {
        const tmp = lists.data.url.data.lists
        this.lists = tmp.filter(
          list => list.gameId !== '98' && list.gameId !== 'S-LB01'
        )
      } else {
        this.lists = lists.data.result
      }
    },
    async getProviders() {
      const lists = await this.$http.get(`member/slot/provider`)
      console.log('LISTS', lists.data.lists)
      lists.data.lists.forEach(element => {
        const d = {
          value: element.provider,
          text: element.providerName
        }
        this.providers.push(d)
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.game-block:hover {
  .play-button {
    display: block;
  }
}

.play-button {
  display: none;
}
</style>
